<template>
    <div>
        <top-nav></top-nav>
        <header-guide></header-guide>
        <bread-crumb></bread-crumb>

        <main class="firm-main">
            <!--头部-->
            <div
                v-if="is_view"
                style="width:100%;line-height:40px;padding-left:20px;background:#f59b15;border-top:1px solid #e9e8e5;"
            >
                <div style="width:1200px;margin:0 auto;color:#fff;">
                    <span>您正在预览个人资料，返回继续操作请点击右边返回按钮！</span>
                    <el-button
                        @click="goBack"
                        class="back-btn"
                        style="height:40px;line-height:38px;width:100px;float:right;text-align:center;background:#587DDC;color:#fff;border:none;margin-right:20px;"
                    >返回</el-button>
                </div>
            </div>
            <div class="firm-hd">
                <div class="container">
                    <div class="firm-logo">
                        <img :src="util.reImg(avatar)" />
                    </div>
                    <div class="firm-txt-cont">
                        <div class="name-wrap">
                            <div class="opera-wrap">
                                <el-button @click="sendMessage">
                                    <i class="jk-icon-faxiaoxi"></i>发消息
                                </el-button>
                                <el-button
                                    @click="keep"
                                    v-if="user.user_type==1 && is_subscribe==0"
                                >
                                    <i class="jk-icon_weiguanzhu"></i>关注该极客
                                </el-button>
                                <el-button
                                    @click="keep"
                                    v-if="user.user_type==2 && is_subscribe==0"
                                >
                                    <i class="jk-icon_weiguanzhu"></i>关注该雇主
                                </el-button>

                                <el-button
                                    @click="keep"
                                    class="attention"
                                    v-if="user.user_type==1 && is_subscribe==1"
                                >
                                    <i class="jk-icon-yiguanzhu"></i>已关注该极客
                                </el-button>
                                <el-button
                                    @click="keep"
                                    class="attention"
                                    v-if="user.user_type==2 && is_subscribe==1"
                                >
                                    <i class="jk-icon-yiguanzhu"></i>已关注该雇主
                                </el-button>
                            </div>
                            <div class="name-group">
                                <h4 class="name" v-text="user.name"></h4>
                                <span
                                    class="tag"
                                    v-if="user.user_type==1"
                                >{{user.attestation_type_value}}极客</span>

                                <span
                                    class="tag"
                                    v-if="user.user_type==2"
                                >{{user.attestation_type_value}}雇主</span>
                            </div>
                        </div>
                        <p class="area">{{user.area_value}}</p>
                        <ul class="firm-msg">
                            <li>
                                <span v-if="user.user_type==1">极客ID：</span>
                                <span v-if="user.user_type==2">雇主ID：</span>
                                <strong>
                                    {{user.geek_id}}
                                    <!-- <i class="jk-icon-fuzhibaise fuzhi"
                                       :data-clipboard-text="user.geek_id"
                                       @click="copy"
                                    style="cursor:pointer"></i>-->
                                </strong>
                            </li>
                            <li v-if="user.user_type==1">
                                <span>完成项目：</span>
                                <strong>{{user.task_total}} 次</strong>
                            </li>
                            <li v-else>
                                <span>发布项目：</span>
                                <strong>{{user.task_total}} 次</strong>
                            </li>
                            <li>
                                <span v-if="user.user_type==1">获得酬劳：</span>
                                <span v-else>支付酬金：</span>
                                <strong>￥{{user.reward}} 元</strong>
                            </li>
                            <li>
                                <span>信用评价：</span>
                                <el-rate
                                    :value="user.credence"
                                    show-score
                                    disabled
                                    disabled-void-color="#ebebeb"
                                    text-color="#fff"
                                ></el-rate>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!--主要内容-->
            <div class="firm-bd">
                <div class="container">
                    <el-menu
                        active-text-color="#fff"
                        :default-active="activeIndex"
                        router
                        style="min-height:100vh;width:200px;float:left"
                    >
                        <NavMenu :navMenus="menuData"></NavMenu>
                    </el-menu>
                    <router-view style="margin-left:240px" v-model="user"></router-view>
                </div>
            </div>
        </main>
        <!--底部-->
        <global-footer></global-footer>
    </div>
</template>
<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import NavMenu from "@/components/firm/NavMenu.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import { mapState } from "vuex";
export default {
    name: "firm",
    metaInfo() {
        return {
            title: this.article_title + " - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
                }, {
                    name: "description",
                    content: this.article_content
                }
            ]
        }
    },
    components: {
        TopNav, // 头部吊顶
        HeaderGuide, //头部指南
        NavMenu: NavMenu, // 菜单
        GlobalFooter, //公共底部
        BreadCrumb //面包屑导航
    },
    data() {
        return {
            activeIndex: "index", // 此处为路由中的页面名字 name
            user: "",
            is_subscribe: 0,
            avatar: "",
            attestation_type: "",
            attestation_types: "",
            is_view: false,
            article_title: '',
            article_content: ''
        };
    },
    computed: {
        ...mapState(["userInfo"]),
        menuData: function () {
            let teamArray = [
                {
                    entity: {
                        id: 0,
                        name: "index?uk=" + this.$route.query.uk,
                        icon: "jk-icon-jianjie_hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "极客简介"
                    }
                },
                {
                    entity: {
                        id: 1,
                        name: "score?uk=" + this.$route.query.uk,
                        icon: "jk-icon-yeji_hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "业绩展示"
                    }
                },
                // {
                //     entity: {
                //         id: 2,
                //         name: "evaluate?uk=" + this.$route.query.uk,
                //         icon: "jk-icon-pingjia_hui", // 导航前面显示的图标， 填写图标名称即可
                //         alias: "客户评价"
                //     }
                // },
                {
                    entity: {
                        id: 3,
                        name: "honor?uk=" + this.$route.query.uk,
                        icon: "jk-icon-zizhi_hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "资质证书"
                    }
                },
                {
                    entity: {
                        id: 4,
                        name: "team?uk=" + this.$route.query.uk,
                        icon: "jk-icon-tuandui_hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "团队人员"
                    }
                },
                {
                    entity: {
                        id: 5,
                        name: "employer?uk=" + this.$route.query.uk,
                        icon: "jk-icon-jianjie_hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "雇主简介"
                    }
                },
                {
                    entity: {
                        id: 6,
                        name: "task?uk=" + this.$route.query.uk,
                        icon: "jk-icon-yeji_hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "他的项目"
                    }
                },
                {
                    entity: {
                        id: 7,
                        name: "article?uk=" + this.$route.query.uk,
                        icon: "jk-icon-tadewenzhang-hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "他的分享"
                    }
                },
                {
                    entity: {
                        id: 8,
                        name: "article2?uk=" + this.$route.query.uk,
                        icon: "jk-icon-tadewenzhang-hui", // 导航前面显示的图标， 填写图标名称即可
                        alias: "他的分享"
                    }
                }
            ];

            // 根据不通用户认证类型输出对应菜单
            let newTeamArray = [];
            teamArray.forEach(item => {
                //极客
                if (this.user.user_type == 1) {
                    //极客不显示雇主主页和雇主项目
                    if (
                        item.entity.id != 5 &&
                        item.entity.id != 6 &&
                        item.entity.id != 8
                    ) {
                        if (this.user.attestation_type == 1) {
                            //个人极客，不显示团队成员
                            if (item.entity.id != 4) {
                                newTeamArray.push(item);
                            }
                        } else if (this.user.attestation_type == 2) {
                            //工作室极客，不显示证书
                            if (item.entity.id != 3) {
                                newTeamArray.push(item);
                            }
                        } else {
                            //单位极客，全部显示
                            newTeamArray.push(item);
                        }
                    }
                } else {
                    //雇主
                    if (
                        item.entity.id == 5 ||
                        item.entity.id == 6 ||
                        item.entity.id == 8
                    ) {
                        newTeamArray.push(item);
                    }
                }
            });

            return newTeamArray;
        }
    },
    created() {
        this.subscribe(); //检查是否订阅了极客
        this.avatar = this.websiteConfigs.sourceUrl + "/upload/image/geek_avatar.png"; //设置默认头像
        this.loadListData(); //加载初始化数据

        let start = window.location.href.lastIndexOf("/");
        let path = window.location.href.slice(start + 1);
        this.activeIndex = path;

        if (this.$route.query.view == 1) {
            this.is_view = true;
        }
    },
    methods: {
        //获取极客或者雇主信息
        loadListData() {
            let path = this.$route.path;
            let uk = this.$route.query.uk;
            let view = this.$route.query.view;
            // console.log(path, uk, view);
            let _this = this;
            _this.post(
                "/user/user/detail",
                {
                    uk: _this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.attestation != 2){
                            _this.$message.error("当前极客未完成认证");
                            _this.$router.push("/geek/list");
                        }
                        //seo
                        _this.article_title = res.data.name;
                        if (res.data.attestation_info.introduction)_this.article_content = res.data.attestation_info.introduction.replace(/<[^>].*?>/g, "").replace(/&nbsp;/g, "").substr(0, 300);//截取文章内容，去除html标签

                        _this.user = res.data;
                        _this.avatar = res.data.avatar;
                        _this.user.credence = parseInt(res.data.credence);
                        _this.user_type = res.data.user_type;
                        
                        if (_this.user_type == 2 && path != '/firm/employer'){
                            _this.$router.push("/firm/employer?uk=" + uk + "&view=" + view);

                        }
                    } else {
                        _this.$message.error("您没有权限查看此用户主页");
                        _this.$router.push("/");
                    }
                }
            );
        },

        //关注用户
        keep(e) {
            let _this = this;
            this.post(
                "/user/user/collect_user",
                {
                    uk: this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data == 1) {
                            //关注成功
                            _this.is_subscribe = 1;
                            _this.$message.success(res.msg);
                        } else {
                            //取消关注
                            _this.is_subscribe = 0;
                            _this.$message.success(res.msg);
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //发送短信
        sendMessage() {
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            if (this.userInfo.isLogin == true) {
                //登录判断
                this.$router.push(
                    "/user/message/sendmsg?uk=" + this.$route.query.uk
                );
            } else {
                this.$message.error("请先登录后再发消息");
                this.$router.push("/login/index?type=login");
            }
        },

        //复制内容
        copy() {
            var clipboard = new this.Clipboard(".fuzhi");
            clipboard.on("success", e => {
                this.$message.success("复制成功");
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                clipboard.destroy();
            });
        },

        //是否关注作者
        subscribe() {
            let _this = this;
            this.post(
                "/user/index/is_subscribe",
                {
                    uk: this.$route.query.uk
                },
                function (res) {
                    if (res.code == 200) {
                        if (res.data.is_subscribe == 1) {
                            _this.is_subscribe = 1;
                        } else {
                            _this.is_subscribe = 0;
                        }
                    } else {
                        _this.$message.error(res.msg);
                    }
                }
            );
        },

        //返回上一页
        goBack() {
            let url = sessionStorage.getItem("currentPageUrl");
            if (url) {
                this.$router.push(url);
            } else {
                this.$router.push("/");
            }
        }
    }
};
</script>
<style lang="less">
@import "../../styles/firm.less";
.firm-logo {
    width: 150px !important;
    height: 150px !important;
    overflow: hidden;
    img {
        width: 150px !important;
        height: auto;
    }
}
.firm-main .back-btn span {
    font-size: 14px !important;
    color: #fff !important;
}
</style>



