<template>
    <div class="navMenu">
        <template v-for="navMenu in navMenus">

            <el-menu-item
                :key="navMenu.entity.id"
                :data="navMenu"
                :index="navMenu.entity.name"
            >
                <i :class="navMenu.entity.icon"></i>
                {{navMenu.entity.alias}}
            </el-menu-item>

        </template>
    </div>
</template>

<script>
export default {
    name: "NavMenu",
    props: ["navMenus"],
    data() {
        return {};
    },
    methods: {}
};
</script>
<style lang="less" scoped>
.el-menu-item {
    text-align: center;
    &.is-active {
        background: #587ddc;
        color: white;
        font-size: 14px;
        i {
            color: white;
        }
        &:before {
            content: "\203A";
            float: right;
            font-size: 20px;
        }
    }
    > i {
        font-size: 14px;
        margin-right: 10px;
        color: #999;
    }
}
</style>
<style lang="less">
.item {
    .el-badge__content {
        top: 27px;
        right: -10px;
        border-color: #f56c6c;
    }
}
</style>
